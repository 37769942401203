import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import { createRecipeUrl } from '../../../../helpers/url/url'
import { Map } from '../../../common/map/map-container'
import * as S from './recipe-map-styled'

const RecipeMapComponent = ({ recipesByCountry }) => {
  const bottomOfListRef = useRef(null);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const handleSelectCountry = (alpha2Code) => {
    setSelectedCountry(alpha2Code);
    bottomOfListRef.current.scrollIntoView();
  }

  const selectedCountrysRecipes = (recipesByCountry[selectedCountry] && recipesByCountry[selectedCountry].recipes) || [];

  return (
    <>
      <S.Subtitle>Recipes by Country</S.Subtitle>

      <S.MapAndListContainer>

        <S.MapContainer>

          <Map
            highlightedCountries={Object.keys(recipesByCountry)}
            handleSelectCountry={handleSelectCountry}
          />

        </S.MapContainer>

        <S.ListContainer>

          <S.Country>
            {(recipesByCountry[selectedCountry] && recipesByCountry[selectedCountry].countryName) || 'Please click any country'}
          </S.Country>

          <hr />

          <S.List>
            {selectedCountrysRecipes.map((recipe, i) => (

              <S.ListItem key={i}>

                <S.A href={createRecipeUrl(recipe.slug)}>{recipe.title}</S.A>

              </S.ListItem>

            ))}
          </S.List>

          <div ref={bottomOfListRef} />
        </S.ListContainer>

      </S.MapAndListContainer>
    </>
  )
}

RecipeMapComponent.propTypes = {
  recipesByCountry: PropTypes.shape({
    recipes: PropTypes.array.isRequired,
    countryName: PropTypes.string.isRequired,
  }).isRequired,
}

export { RecipeMapComponent }