import React from 'react'
import PropTypes from 'prop-types'

import { List, ListItem } from '../../../common/snippets/snippet/snippet-styled'
import { RecipeSnippet } from '../../../common/snippets/recipe-snippet/recipe-snippet-component'
import { ROUTES } from '../../../../constants/routes'
import * as S from './most-recent-recipes-styled'

const MostRecentRecipesComponent = ({ recipes }) => (
  <S.Container>

    <S.Subtitle>
      Newest Recipes
    </S.Subtitle>

    <List>
      {recipes.map(recipe =>
        <ListItem key={recipe.id}>

          <RecipeSnippet
            title={recipe.title}
            slug={recipe.slug}
            countries={recipe.countries}
            description={recipe.description}
            image={recipe.image}
          />

        </ListItem>)}
    </List>

    <S.ViewAllLinkContainer>

      <S.ViewAllLink to={ROUTES.recipe.path}>
        VIEW ALL
      </S.ViewAllLink>

    </S.ViewAllLinkContainer>

  </S.Container>
)

MostRecentRecipesComponent.propTypes = {
  recipes: PropTypes.array.isRequired,
}

export { MostRecentRecipesComponent };