import React from "react"

import Layout from '../components/common/layout/layout-component'
import SEO from '../components/common/seo'
import { Home } from '../components/pages/home/home-component'

const IndexPage = () => (
  <Layout>

    <SEO
      title="Home"
      keywords={[`travel`, `recipes`, `gatsby`, `authentic`]}
    />

    <Home />

  </Layout>
)

export default IndexPage