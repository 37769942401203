import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

import { RecipeMapComponent } from './recipe-map-component'

const RecipeMap = (props) => (
  <StaticQuery
    query={recipeMapQuery}
    render={data => {

      // Organize recipes by country
      const recipesByCountry = {};

      data.allStrapiCountry.edges.forEach(edge => {
        const { Alpha2Code, Name, ShortName, recipes } = edge.node;

        // Sort recipes alphabetically
        recipes.sort((a, b) => {
          const titleA =  a.title.toUpperCase();
          const titleB =  b.title.toUpperCase();

          if (titleA < titleB) return -1;
          
          if (titleA > titleB) return 1;

          return 0;
        });

        recipesByCountry[Alpha2Code] = {
          recipes: recipes,
          countryName: ShortName || Name,
        };
      })

      return (
        <RecipeMapComponent
          recipesByCountry={recipesByCountry}
          {...props}
        />
      )

    }}
  />
)

const recipeMapQuery = graphql`
  query RecipeMapQuery {
    allStrapiCountry (filter: {
      recipes: {
        elemMatch: {
          title: { ne: null }
        }
      }
    }) {
      edges {
        node {
          Alpha2Code
          Name
          ShortName
          recipes {
            title
            slug
          }
        }
      }
    }
  }
`;

export { RecipeMap };