import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

import { MostRecentVideoComponent } from './most-recent-video-component'

const MostRecentVideo = (props) => (
  <StaticQuery
    query={mostRecentVideoQuery}
    render={data => {
      const videoSrc = data.allStrapiRecipe.edges[0].node.video || null;
      const title = data.allStrapiRecipe.edges[0].node.title;
      return (
        <MostRecentVideoComponent
          videoSrc={videoSrc}
          title={title}
          width="700"
          height="500"
          {...props}
        />
      )
    }}
  />
)

const mostRecentVideoQuery = graphql`
  query MostRecentVideoQuery {
    allStrapiRecipe(
      limit: 1
      sort: {
        fields: [date]
        order: DESC
      }
      filter: {
        video: { ne: null }
      }
    ) {
      edges {
        node {
          id
          date
          title
          video
        }
      }
    }
  }
`;

export { MostRecentVideo };