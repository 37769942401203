import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

import { MostRecentRecipesComponent } from './most-recent-recipes-component'

const MostRecentRecipes = (props) => (
  <StaticQuery
    query={mostRecentRecipesQuery}
    render={data => (
      <MostRecentRecipesComponent
        recipes={data.allStrapiRecipe.edges.map(edge => edge.node)}
        {...props}
      />
    )}
  />
)

const mostRecentRecipesQuery = graphql`
  query MostRecentRecipesQuery {
    allStrapiRecipe(
      limit: 3
      sort: {
        fields: [date]
        order: DESC
      }
    ) {
      edges {
        node {
          id
          title
          slug
          image {
            childImageSharp {
              fluid(maxWidth: 450, quality: 95) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          date
          countries {
            Name
            ShortName
          }
          description
        }
      }
    }
  }
`;

export { MostRecentRecipes }