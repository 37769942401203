import styled from 'styled-components'
import { Link } from 'gatsby'

import { MainSubtitle } from '../../../common/text-styled'
import { media } from '../../../../constants/media'

export const Container = styled.div`
  margin-bottom: 50px;
  @media ${media.medium.query} {
    margin-bottom: 100px;
  }
`;

export const Subtitle = styled(MainSubtitle)`
  margin: 0 20px;
  @media ${media.medium.query} {
    margin: 0 50px;
  }
`;

export const ViewAllLinkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 25px;
  @media ${media.medium.query} {
    margin-top: 50px;
  }
`;

export const ViewAllLink = styled(Link)`
  font-family: ${props => props.theme.fonts.poppins};
  text-decoration: none;

  font-size: 2rem;
  padding-left: 50px;
  padding-right: 50px;
  @media ${media.medium.query} {
    font-size: 2.5rem;
    padding-left: 150px;
    padding-right: 150px;
  }

  color: ${props => props.theme.colors.salmon};
  font-weight: bold;
  border-top: 2px solid ${props => props.theme.colors.salmon};
  :hover {
    border-top: 2px solid ${props => props.theme.colors.pacific};
    color: ${props => props.theme.colors.pacific};
  }

  :visited {
    color: ${props => props.theme.colors.salmon};
    :hover {
      color: ${props => props.theme.colors.pacific};
    }
  }
`;