import React from 'react'
import FadeIn from 'react-fade-in'

import { Welcome } from './welcome/welcome-component'
import { MostRecentRecipes } from './most-recent-recipes/most-recent-recipes-container'
import { MostRecentVideo } from './most-recent-video/most-recent-video-container'
import { RecipeMap } from './recipe-map/recipe-map-container'

const Home = () => (
  <FadeIn>

    <Welcome />

    <MostRecentRecipes />

    <MostRecentVideo />

    <RecipeMap />

  </FadeIn>
)

export { Home };