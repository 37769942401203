import styled from 'styled-components'

import { MainSubtitle, CountryTitle, Link } from '../../../common/text-styled'
import { media } from '../../../../constants/media'

export const Subtitle = styled(MainSubtitle)`
  margin: 0 20px;
  margin-bottom: 25px;
  @media ${media.medium.query} {
    margin: 0 50px;
    margin-bottom: 50px;
  }
`

export const MapAndListContainer = styled.div`
  width: 100%;
  text-align: center;

  & svg.datamap {
    right: 0.25rem;
  }
`;

export const MapContainer = styled.div`
`;

export const ListContainer = styled.div`
  margin: 1rem;
`;

export const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const ListItem = styled.li`
  margin: 1.25rem 0;
`;

export const Country = styled(CountryTitle)`
`;

export const A = styled(Link)`
`;