import React from 'react'

import { EmailSubscribeForm } from '../../../common/email-subscribe-form/email-subscribe-form-container'
import * as S from './welcome-component-styled'

export const Welcome = () => (
  <S.Container>

    <S.Title>
      Welcome to Global Grubbr!
    </S.Title>

    <S.Text>
      My name is Justin and I travel to eat and learn new recipes. This website is a collection of my favorite recipes I've learned abroad.
    </S.Text>

    <EmailSubscribeForm />

  </S.Container>
);