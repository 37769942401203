import styled from 'styled-components'

import { MainTitle, BodyText } from '../../../common/text-styled'
import { media } from '../../../../constants/media'

export const Container = styled.div`
  margin: 0 20px;
  margin-bottom: 60px;
  @media ${media.medium.query} {
    margin: 0 50px;
    margin-bottom: 100px;
  }
`;

export const Title = styled(MainTitle)`
`;

export const Text = styled(BodyText)`
`;